/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import { I18nProvider } from "./src/common/i18nContext"
export const wrapRootElement = ({ element }) => (
  <I18nProvider>{element}</I18nProvider>
)