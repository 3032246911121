import React from "react"

const defaultState = {
  lang: "pl",
  setLanguage: () => {
  },
}
const I18nContext = React.createContext(defaultState)

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
// const supportsDarkMode = () =>
//   window.matchMedia("(prefers-color-scheme: dark)").matches === true

class I18nProvider extends React.Component {
  state = {
    lang: "pl",
  }
  setLanguage = (lang) => {
    localStorage.setItem("lang", JSON.stringify(lang))
    this.setState({ lang })
  }

  componentDidMount() {
    // Getting dark mode value from localStorage!
    const lang = JSON.parse(localStorage.getItem("lang"))
    if(lang) {
      this.setState({ lang })
    }
  }

  render() {
    const { children } = this.props
    const { lang } = this.state
    console.log(lang)
    return (
      <I18nContext.Provider
        value={{
          lang,
          setLanguage: this.setLanguage,
        }}
      >
        {children}
      </I18nContext.Provider>
    )
  }
}

export default I18nContext
export { I18nProvider }